import { useAuth } from "./AuthContext";
import { FlowIdentifier, Tokens } from "@okta/okta-auth-js";
import { useOktaAuth } from "@okta/okta-react";
import OktaSignIn from "@okta/okta-signin-widget";
import "@okta/okta-signin-widget/css/okta-sign-in.min.css";
import { getLastVisited } from "@stores/lastVisited";
import { useThemeMode } from "flowbite-react";
import { useEffect, useRef } from "react";
import { twMerge } from "tailwind-merge";
import { LIGHT_LOGO, DARK_LOGO } from "@config/constants";

// Make sure to import this after the imports
import "./login.css";
interface IOktaLoginProps {
  flow?: FlowIdentifier;
}

const OktaLogin = ({ flow = "login" }: IOktaLoginProps) => {
  const { oktaAuth } = useOktaAuth();
  const { oktaOptions } = useAuth();
  const loginWidgetRef = useRef(null);
  const { computedMode } = useThemeMode();
  useEffect(() => {
    if (!loginWidgetRef.current) {
      return;
    }
    // Initialize OktaSignIn widget with the correct logo based on computedMode
    const loginWidget = new OktaSignIn({
      ...oktaOptions,
      logo: computedMode === "dark" ? DARK_LOGO : LIGHT_LOGO, // Use computedMode for logo
      flow: flow,
    });

    const onSuccess = (tokens: Tokens) => {
      const lastVisitedPage = getLastVisited();
      if (lastVisitedPage) {
        oktaAuth.setOriginalUri(lastVisitedPage);
      }
      oktaAuth.handleLoginRedirect(tokens);
    };

    const onError = (err: string) => {
      console.log("Sign in error:", err);
    };

    loginWidget
      .showSignInToGetTokens({
        el: loginWidgetRef.current,
      })
      .then(onSuccess)
      .catch(onError);

    return () => loginWidget.remove();
  }, [oktaAuth, oktaOptions, computedMode]);

  return (
    <div
      className={twMerge(
        "flex flex-col justify-center items-center h-screen gap-8",
        computedMode === "dark" ? "dark" : "",
      )}
    >
      <img
        src={computedMode === "dark" ? DARK_LOGO : LIGHT_LOGO} // Use the same images as SiteHeader based on computedMode
        alt="logo"
        className="w-40"
      />
      <div id="login-container" ref={loginWidgetRef} />
    </div>
  );
};
export default OktaLogin;
