import { useState, useRef, useEffect } from "react";
import { DebounceInput } from "react-debounce-input";
import { BiSearchAlt2 } from "react-icons/bi";
import { AiOutlineEnter } from "react-icons/ai";
import { useLocation, useNavigate } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import customTheme from "@customTheme";

const SearchButton = ({ placeholder = "Search..." }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const searchInputRef = useRef<HTMLInputElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const location = useLocation();

  const hideOnRoutes = ["/search"];
  const shouldHide = hideOnRoutes.includes(location.pathname);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target as Node)
      ) {
        setIsExpanded(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleExpandClick = () => {
    setIsExpanded(true);
    // give some time for the component to be rendered
    // before the focus
    requestAnimationFrame(() => {
      searchInputRef.current?.focus();
    });
  };

  const handleSearch = () => {
    const query = searchInputRef.current?.value || searchQuery;
    if (query.trim().length > 0) {
      navigate(`/search?q=${encodeURIComponent(query)}`);
      setSearchQuery(""); // Clear the input
      setIsExpanded(false); // Collapse the search input field
    }
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  // Return JSX based on `shouldHide`
  return !shouldHide ? (
    <div ref={wrapperRef} className="relative flex items-center">
      {!isExpanded ? (
        <button
          onClick={handleExpandClick}
          className="p-2 rounded-full hover:text-bold focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75"
          aria-label="Expand search"
        >
          <BiSearchAlt2
            className={twMerge(
              "h-6 w-6",
              location.pathname === "/home"
                ? "text-black dark:text-white"
                : "text-gray-400 dark:text-gray-400",
            )}
          />
        </button>
      ) : (
        <div className="relative w-full max-w-lg transition-all">
          <DebounceInput
            inputRef={searchInputRef}
            className={twMerge(
              customTheme?.textInput?.field?.input?.colors?.info,
              "w-full h-10 pl-4 pr-10 py-1 text-lg",
              "bg-gray-50 border border-gray-300 rounded-[7px] text-sm font-normal",
            )}
            debounceTimeout={200}
            onChange={onChange}
            onKeyDown={handleKeyDown}
            value={searchQuery}
            placeholder={placeholder}
          />
          <div className="absolute top-1/2 right-3 transform -translate-y-1/2 flex items-center gap-2">
            {searchQuery && (
              <button onClick={handleSearch} aria-label="Submit search">
                <AiOutlineEnter className="text-gray-500 text-xl" />
              </button>
            )}
            <BiSearchAlt2 className="text-gray-500 text-2xl" />
          </div>
        </div>
      )}
    </div>
  ) : null;
};

export default SearchButton;
