const storageKey = "carc-auth-api-tokens";

type TUserInfoData = {
  clientUUID: string;
  clientId: string;
  userEmail: string;
  userUUID: string;
};

export const setApiToken = (data: { clientId: string; api_token: string }) => {
  localStorage.setItem(storageKey, JSON.stringify(data));
};
export const getApiToken = () => {
  const data = localStorage.getItem(storageKey);
  return data ? JSON.parse(data) : null;
};

export const setUserInfo = (data: TUserInfoData) => {
  const jsonString = JSON.stringify({
    client_uuid: data.clientUUID,
    client_id: data.clientId,
    user_email: data.userEmail,
    user_uuid: data.userUUID,
  });
  const user_info = btoa(jsonString);
  localStorage.setItem("user-info", user_info);
};

export const getUserInfo = () => {
  return localStorage.getItem("user-info");
};

export const getClientId = () => {
  return getApiToken()?.clientId;
};

export const doesApiTokenExist = () => {
  return localStorage.getItem(storageKey) !== null;
};
