import { useScrollToTop } from "@hooks/useScrollToTop";
import { useLocation } from "react-router-dom";

export function RouteScrollToTop() {
  const { pathname } = useLocation();

  useScrollToTop({ dep: pathname });

  return null;
}
