const loginCallback = "/login/callback";

const config = Object.freeze({
  isDev: import.meta.env.DEV,
  okta: {
    clientId: import.meta.env.VITE_OKTA_CLIENT_ID ?? "",
    redirectUri: `${window.location.origin}${loginCallback}`,
    issuer: import.meta.env.VITE_OKTA_ISSUER ?? "",
    pkce: true,
    scopes: ["openid", "profile", "email", "groups"],
  },
  baseUrl: "/home",
  apiUrl: import.meta.env.VITE_API_BASE_URL ?? "",
  apiSampleCodeBaseUrl:
    import.meta.env.VITE_API_SAMPLE_CODE_BASE_URL ??
    "https://platform.carbonarc.co/api/v2/data/insights",
  superset: {
    url: import.meta.env.VITE_SUPERSET_URL ?? "https://insights.carbonarc.co",
  },
  featureFlags: {
    hiddenNavbarItems:
      import.meta.env.VITE_FF_HIDDEN_NAVBAR_ITEMS?.split(",") ?? [],
  },
  carcAuth: {
    url: import.meta.env.VITE_CARC_AUTH_SERVICE_URL ?? "",
    product_id: import.meta.env.VITE_CARC_AUTH_PRODUCT_ID ?? "",
  },
  platformDocsUrl: import.meta.env.VITE_PLATFORM_DOCS_URL ?? "",
});

export default config;
