import useDropdown from "../../hooks/useDropdown";
import useOutsideClick from "../../hooks/useOutsideClick";
import { useAuth } from "../Okta";
import DropdownMenu from "./DropdownMenu";
import ProfileHeader from "./ProfileHeader";
import React, { useRef } from "react";
import { FiUser, FiUsers, FiPower } from "react-icons/fi";
// import { DarkThemeToggle } from "flowbite-react";
// import { useThemeMode } from "flowbite-react";

const UserProfileDropdown: React.FC = () => {
  const { signOut, user } = useAuth();
  const dropdownRef = useRef<HTMLDivElement>(null);
  const { isOpen, toggleDropdown, setIsOpen } = useDropdown();
  // const { toggleMode } = useThemeMode();

  useOutsideClick(dropdownRef, () => setIsOpen(false));

  const handleSignOut = () => {
    signOut();
    setIsOpen(false);
  };

  const items = [
    { name: "My Profile", href: "/profile", icon: FiUser },
    { name: "My Team", href: "/team", icon: FiUsers },
    { name: "Log Out", onClick: handleSignOut, icon: FiPower },
  ];

  if (!user) return null;

  return (
    <div className="relative" ref={dropdownRef}>
      <ProfileHeader
        userName={user?.name || null}
        toggleDropdown={toggleDropdown}
      />
      {isOpen && (
        <div
          id="dropdown-menu"
          className="absolute right-0 mt-3 w-40 bg-white dark:bg-black shadow-lg z-50 rounded-lg"
        >
          <DropdownMenu
            items={items}
            onClose={() => setIsOpen(false)}
            // darkThemeToggle={
            //   <div
            //     onClick={toggleMode}
            //     className="p-1 hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none dark:text-white cursor-pointer flex items-center"
            //   >
            //     <DarkThemeToggle />
            //     <span className="ml-1 font-medium text-gray-900 dark:text-white">
            //       Color Theme
            //     </span>
            //   </div>
            // }
          />
        </div>
      )}
    </div>
  );
};

export default UserProfileDropdown;
