import { AuthProvider } from "@components/Okta";
import Toaster from "@components/base/Toaster/Toaster";
import { queryClient } from "@config/react-query";
import customTheme from "@customTheme";
import ErrorBoundary from "@pages/ErrorBoundary";
import { router } from "@routes/router";
import { QueryClientProvider } from "@tanstack/react-query";
import { Flowbite } from "flowbite-react";
import { useEffect } from "react";
import { RouterProvider } from "react-router-dom";
import "utils/i18n";

export default function App() {
  useEffect(() => {
    // Ensure the app always uses the `light` class on the root element
    document.documentElement.classList.remove("dark");
    document.documentElement.classList.add("light");
    // Update localStorage to set `flowbite-theme-mode` to `light`
    localStorage.setItem("flowbite-theme-mode", "light");
  }, []); // Run once on mount

  return (
    <Flowbite theme={{ theme: customTheme }}>
      <ErrorBoundary>
        <QueryClientProvider client={queryClient}>
          <AuthProvider>
            <RouterProvider router={router} />
          </AuthProvider>
        </QueryClientProvider>
      </ErrorBoundary>
      <Toaster />
    </Flowbite>
  );
}
