import { useEffect } from "react";

type TUseScrollToTopProps = {
  dep?: string | number | boolean;
};

/**
 * Hooks to scroll to top when dependency changes
 */
export const useScrollToTop = ({ dep }: TUseScrollToTopProps) => {
  useEffect(() => {
    /**
     * scroll to top dep changes
     */
    window.scrollTo(0, 0);
  }, [dep]);

  return null;
};
