import React from "react";

export enum Flags {
  SHOW_HOME = "show_home",
}

export type PageData = {
  label: string;
  path: string;
  badge?: string;
  icon?: React.ElementType;
  component: React.LazyExoticComponent<React.ComponentType<any>>;
};

export const Divider = "";

export enum Page {
  HOME = "home",
  PROFILE = "profile",
  TEAM = "team",
  RESET_PASSWORD = "reset_password",
  INSIGHTS = "insights",
  INSIGHTS_BUILDER = "insights/builder",
  INSIGHTS_DETAILS = "insights/details",
  INSIGHTS_DETAILS_NEW = "insights/details-next", // temporary to not override current insights details page
  ONTOLOGY = "ontology",
  ONTOLOGY_DETAILS = "ontology/details",
  ONTOLOGY_CATEGORY_DETAILS = "ontology/category-details",
  WORKSPACES = "workspaces",
  WORKSPACES_NEW = "workspaces_new",
  DASHBOARDS = "dashboards",
  DATA_LIBRARY = "data-library",
  DATA_LIBRARY_ALL_DATA = "data-library/all-data",
  API = "api",
  ALL_DATA = "all-data",
  SEARCH = "search",
  ENTITY_WORKBOOKS = "entity_workbooks",
  ENTITY_WORKBOOK_DETAILS = "entity_workbook",
  REQUEST_ENTITY = "request_entity",
  CONTACT_US = "contact_us",
}
export const pageData: Record<Page, PageData> = {
  [Page.HOME]: {
    label: "Home",
    path: `/${Page.HOME}`,
    component: React.lazy(() => import("@pages/home")),
  },
  [Page.PROFILE]: {
    label: "Profile",
    path: `/${Page.PROFILE}`,
    component: React.lazy(() => import("@pages/profile")),
  },
  [Page.RESET_PASSWORD]: {
    label: "Reset Password",
    path: `/${Page.RESET_PASSWORD}`,
    component: React.lazy(() => import("@pages/reset-password")),
  },
  [Page.INSIGHTS]: {
    label: "Insights",
    path: `/${Page.INSIGHTS}/:group?`,
    component: React.lazy(() => import("@pages/insights/index")),
  },
  [Page.INSIGHTS_BUILDER]: {
    label: "Insights Builder",
    path: `/${Page.INSIGHTS_BUILDER}`,
    component: React.lazy(() => import("@pages/insights/builder")),
  },
  [Page.INSIGHTS_DETAILS]: {
    label: "Insights Details",
    path: `/${Page.INSIGHTS_DETAILS}`,
    component: React.lazy(() => import("@pages/insights/details")),
  },
  [Page.INSIGHTS_DETAILS_NEW]: {
    label: "Insights Details",
    path: `/${Page.INSIGHTS_DETAILS_NEW}`,
    component: React.lazy(() => import("@pages/insights/DetailsNew")),
  },
  [Page.ONTOLOGY]: {
    label: "Ontology",
    path: `/${Page.ONTOLOGY}`,
    component: React.lazy(() => import("@pages/ontology")),
  },
  [Page.ONTOLOGY_DETAILS]: {
    label: "Ontology",
    path: `/${Page.ONTOLOGY_DETAILS}`,
    component: React.lazy(
      () => import("@pages/ontology/OntologyEntityDetails"),
    ),
  },
  [Page.ONTOLOGY_CATEGORY_DETAILS]: {
    label: "Ontology",
    path: `/${Page.ONTOLOGY_CATEGORY_DETAILS}`,
    component: React.lazy(
      () => import("@pages/ontology/OntologyCategoryHierarchyDetails"),
    ),
  },
  [Page.TEAM]: {
    label: "Team",
    path: `/${Page.TEAM}`,
    component: React.lazy(() => import("@pages/team")),
  },
  [Page.WORKSPACES]: {
    label: "Workspace",
    path: "/workspaces",
    component: React.lazy(() => import("@pages/workspaces")),
  },
  [Page.WORKSPACES_NEW]: {
    label: "New Workspace",
    path: "/workspaces/new",
    component: React.lazy(() => import("@pages/workspaces-new")),
  },
  [Page.DASHBOARDS]: {
    label: "Dashboards",
    path: "/dashboards/*",
    component: React.lazy(() => import("@pages/dashboards")),
  },
  [Page.DATA_LIBRARY]: {
    label: "Data Library",
    path: "/data-library/:tab?",
    component: React.lazy(() => import("@pages/data-library")),
  },
  [Page.DATA_LIBRARY_ALL_DATA]: {
    label: "All Data in Data Library",
    path: "/data-library/all-data/:type/:key",
    component: React.lazy(() => import("@pages/data-library-all-data")),
  },
  [Page.API]: {
    label: "API",
    path: "/api",
    component: React.lazy(() => import("@pages/api-explore")),
  },
  [Page.ALL_DATA]: {
    label: "All Data",
    path: "/all-data/:type/:key",
    component: React.lazy(() => import("@pages/all-data")),
  },
  [Page.SEARCH]: {
    label: "Search Page",
    path: "/search",
    component: React.lazy(() => import("@pages/search")),
  },
  [Page.ENTITY_WORKBOOKS]: {
    label: "Entity Workbooks",
    path: "/entity-workbooks",
    component: React.lazy(() => import("@pages/entity-workbooks")),
  },
  [Page.ENTITY_WORKBOOK_DETAILS]: {
    label: "Entity Workbook Details",
    path: "/entity-workbook/:entityWorkbookID",
    component: React.lazy(() => import("@pages/entity-workbook-details")),
  },
  [Page.REQUEST_ENTITY]: {
    label: "Request An Entity",
    path: "/request-entity",
    component: React.lazy(() => import("@pages/request-entity")),
  },
  [Page.CONTACT_US]: {
    label: "Contact Us",
    path: "/contact-us",
    component: React.lazy(() => import("@pages/contact-us")),
  },
};

/**
 * Create URL routes from pageData for page navigation.
 */
type PageKeys = keyof typeof pageData;
export const urlRoutes = Object.keys(pageData).reduce(
  (acc, key) => {
    acc[key as PageKeys] = pageData[key as Page].path;
    return acc;
  },
  {} as Record<PageKeys, string>,
);
